import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import CreateAlarmSystemDialogContent from "./fireAlarmSystem/CreateAlarmSystemDialogContent";
import { BaseDialog } from "./StyledComponents/Dialog";
import OperationFailedDialogContent from "./operations/OperationFailedModalContent";
import { ModelType, PermissionType } from "adapters/auth";
import useHasPermission from "hooks/useHasPermission";

interface DialogConfig {
    key: string;
    component: React.ReactNode;
    permissionParams: [ModelType, PermissionType<ModelType>];
}
const GlobalDialogs: React.FC = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const { hasPermission } = useHasPermission();

    const onClose = useCallback((): void => {
        searchParams.delete("modal");
        setSearchParams(searchParams);
        queryClient.invalidateQueries({
            queryKey: ['fireAlarmSystems'],
            refetchType: 'all',
        });
    }, [queryClient, searchParams, setSearchParams]);

    
    const openDialogs = useMemo(() => {
        const dialogConfigs: DialogConfig[] = [
            {
                key: "create-alarm",
                permissionParams: ["firealarmsystem", "add_firealarmsystem"],
                component: <CreateAlarmSystemDialogContent onClose={onClose} />
            },
            {
                key: "operations-error",
                permissionParams: ["command", "add_command"],
                component: <OperationFailedDialogContent onClose={onClose} />
            }
        ];
        const queryModals = searchParams.getAll("modal");
        return dialogConfigs.filter(config => {
            return queryModals.includes(config.key) && hasPermission(...config.permissionParams);
        });

    }, [hasPermission, onClose, searchParams])


    return (
        <>
            {openDialogs.map((conf, index) => (
                <BaseDialog 
                    fullWidth
                    maxWidth="xs"
                    key={index}
                    onClose={onClose} 
                    open>
                    <>
                        {conf.component}
                    </>
                </BaseDialog>
            ))}
        </>
    )
};

export default GlobalDialogs;