import { Add, Download } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, List, ListItem, ListItemText, ListSubheader, Skeleton, Typography } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { createInvoiceExport, fetchInvoiceExport } from "adapters/invoicing";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import { format } from "date-fns";
import { useHasPermission } from "hooks/useHasPermission";
import React from "react";
import { useInView } from "react-intersection-observer";
import { isLastInArray } from "utils/arrays";

const LoadingInvoiceExportSkeleton: React.FC = () => {
    return (
        <ListItem
            disableGutters
            divider
            secondaryAction={
                <Skeleton variant="circular" width="24px" height="24px" />
            }
        >
            <ListItemText
                sx={{ margin: 0 }}
                primaryTypographyProps={{ variant: "subtitle2" }}
                primary={<Skeleton width="50%" />}
            />
        </ListItem>
    );
};


const InvoiceExportView: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { ref, inView } = useInView();
    const { hasPermission } = useHasPermission();

    const invoiceExportQuery = useInfiniteQuery({
        queryKey: ["invoice-export"],
        queryFn: async ({ pageParam }) => {
            const params = {
                page: pageParam
            };
            const res = await fetchInvoiceExport({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const invoiceExportMutation = useMutation({
        mutationKey: ["create-invoice-export"],
        mutationFn: createInvoiceExport,
        onSuccess: () => {
            openSnack(localize("invoicing.invoice-export-created"), "success")
            invoiceExportQuery.refetch();
        }
    });

    React.useEffect(() => {
        if (inView) {
            invoiceExportQuery.fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);


    return (
        <Box maxWidth="sm">
            <Typography variant="h2" color="primary" gutterBottom>
                {localize("navigation.invoice-export")}
            </Typography>
            <List
                sx={{ mt: "3em" }}
                subheader={
                    <ListSubheader
                        disableGutters
                        component="div"
                        sx={{
                            display: "flex",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                            backgroundColor: (theme) => theme.palette.background.default
                        }}
                    >
                        {hasPermission("invoiceexport", "add_invoiceexport") &&
                            <>
                                <Typography variant="subtitle1">
                                    {localize("invoicing.current-invoice-export")}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    endIcon={invoiceExportMutation.isPending ? <CircularProgress size="1rem" color="secondary" /> : <Add />}
                                    onClick={() => invoiceExportMutation.mutate()}
                                >
                                    {localize("invoicing.create-new-invoice-export")}
                                </Button>
                            </>
                        }
                    </ListSubheader>
                }
            >
                {hasPermission("invoiceexport", "add_invoiceexport") && invoiceExportQuery.isFetching && !invoiceExportQuery.isFetchingNextPage ? (
                    <>
                        <LoadingInvoiceExportSkeleton />
                        <LoadingInvoiceExportSkeleton />
                        <LoadingInvoiceExportSkeleton />
                        <LoadingInvoiceExportSkeleton />
                    </>
                ) : invoiceExportQuery.data?.pages.map((group, i) => group.data.map((invoice, j) => (
                    <ListItem
                        disableGutters
                        divider
                        key={invoice.id}
                        ref={isLastInArray(invoiceExportQuery.data?.pages, i) && isLastInArray(group.data, j) ? ref : null}
                        secondaryAction={
                            <IconButton onClick={() => window.open(invoice.file)}>
                                <Download />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primaryTypographyProps={{ variant: "subtitle2" }}
                            primary={format(new Date(invoice.created), "Pp")}
                            secondary={`${localize("invoicing.invoice-export-created-by")} ${invoice.created_by ? invoice.created_by : "system"}`}
                        />
                    </ListItem>
                )))}
                {invoiceExportQuery.isFetchingNextPage && (
                    <LoadingInvoiceExportSkeleton />
                )}
            </List>
        </Box>
    );
};

export default InvoiceExportView;