import React, { useCallback } from "react";
import { fetchSSDFiles } from "adapters/fireAlarmSystem";
import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { LoadingSkeletonRows, StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import useDateFormat from "hooks/useDateFormat";
import { Columns, RenderTablesHeaders } from "components/RenderTablesHeaders";
import { ISSDFile } from "interfaces/fireAlarmSystem";
import { RenderMobileTablesContent } from "components/RenderMobileTablesContent";

type TSortHeader = "version" | "based_on_version" | "timestamp" | "description" | "uploaded_by__email";

const SSDFilesTable: React.FC<{ alarmSystemId: string | undefined }> = ({ alarmSystemId }) => {
    const { localize } = React.useContext(LocaleContext);
    const [order, setOrder] = React.useState<"asc" | "desc">('desc');
    const [orderBy, setOrderBy] = React.useState<TSortHeader>('version');
    const [totalItems, setTotalItems] = React.useState(0);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));

    const ssdFilesQuery = useInfiniteQuery({
        queryKey: ["ssd-files", alarmSystemId, order, orderBy],
        queryFn: async ({ pageParam }) => {
            if (alarmSystemId) {
                const params = {
                    page: pageParam,
                    ordering: order === "asc" ? orderBy : `-${orderBy}`,
                };
                const res = await fetchSSDFiles(alarmSystemId, { params: params });
                setTotalItems(res.data.count);
                return {
                    data: res.data.results,
                    nextPage: res.data.next ? pageParam + 1 : undefined,
                }
            }
            return {
                data: [],
                nextPage: undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        enabled: !!alarmSystemId
    });

    const handleRequestSort = (param: TSortHeader | string) => {
        const isAsc = orderBy === param && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(param as TSortHeader);
    };

    React.useEffect(() => {
        if (inView) {
            ssdFilesQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const columns: Columns[] = [
        {
            text: "ssd-files.version",
            sort_header: "version"
        },
        {
            text: "ssd-files.based-on-version"
        },
        {
            text: "ssd-files.timestamp",
            sort_header: "timestamp"
        },
        {
            text: "ssd-files.description"
        },
        {
            text: "ssd-files.uploaded-by",
            sort_header: "uploaded_by__email"
        }
    ];
    const basedOnVersionCell = useCallback((version?: number) => {
        if (!version) {
            return "-"
        }
        return version
    }, []);

    const mobileRows = (ssdFile: ISSDFile) => {
        return [
            {
                title: "ssd-files.version",
                content: ssdFile.version,
                sortHeader: "version"
            }, {
                title: "ssd-files.based-on-version",
                content: basedOnVersionCell(ssdFile.based_on_version)
            }, {
                title: "ssd-files.timestamp",
                content: formatDate(ssdFile.timestamp),
                sortHeader: "timestamp"
            }, {
                title: "ssd-files.description",
                content: ssdFile.description
            }, {
                title: "ssd-files.uploaded-by",
                content: ssdFile.uploaded_by_email,
                sortHeader: "uploaded_by__email"
            }
        ]
    };

    return (
        <>
            <StyledTableContainer>
                <Table sx={{ width: '100%' }} size="small" stickyHeader >
                    {!isSm ?
                    <TableHead>
                        <RenderTablesHeaders
                            columns={columns}
                            handleRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                    </TableHead>
                    : ""}
                    <TableBody>
                        {ssdFilesQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("journal.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        ) : ssdFilesQuery.data?.pages.map((datablock, i) => datablock.data.map((entity, j) => (
                             <StyledTableRow
                                key={entity.id}
                                ref={isLastInArray(ssdFilesQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                            >
                                {isSm ? (
                                    <StyledTableCell>
                                        {(mobileRows(entity).map((row, index) => (
                                            <RenderMobileTablesContent key={index}
                                                title={row.title} content={row.content}
                                                sortHeader={row.sortHeader} orderBy={orderBy} order={order}
                                                handleRequestSort={handleRequestSort}
                                            />
                                        )))}
                                    </StyledTableCell>
                                ) : (
                                    <>
                                        <StyledTableCell>{entity.version}</StyledTableCell>
                                        <StyledTableCell>{basedOnVersionCell(entity.based_on_version)}</StyledTableCell>
                                        <StyledTableCell>{formatDate(entity.timestamp)}</StyledTableCell>
                                        <StyledTableCell>{entity.description}</StyledTableCell>
                                        <StyledTableCell>{entity.uploaded_by_email}</StyledTableCell>
                                    </>
                                )}
                            </StyledTableRow>
                        )))}
                        {ssdFilesQuery.isFetchingNextPage && ref != null && (
                            <LoadingSkeletonRows columns={ isSm ? 1 : columns.length} />
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <Grid container>
                <Grid item xs={11}>
                    <Typography marginTop="1.0em" align="right">
                        {localize("generics.total-items")} {totalItems}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default SSDFilesTable;