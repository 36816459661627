import { Box, Button, CircularProgress, Grid, LinearProgress, Skeleton, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React, { MouseEventHandler } from "react";
import { NavigateNext, Refresh } from "@mui/icons-material";

interface SensorInfoTableFotterRowProps {
    totalCount: number,
    handleUpdateQuery: MouseEventHandler<HTMLButtonElement>,
    isExecuting: boolean,
    currentProgress: number,
    lastUpdatedAt: string,
    pageDirection: number,
    gatewayNotConnected: boolean,
    communicationErrorExists: boolean,
    isLoading: boolean
}


const SensorInfoTableFotterRow: React.FC<SensorInfoTableFotterRowProps> = ({ totalCount, handleUpdateQuery, isExecuting,
    currentProgress, lastUpdatedAt, pageDirection, gatewayNotConnected, communicationErrorExists, isLoading }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            {isLoading ?
                <>
                    <Skeleton width={"250px"} />
                    <Skeleton width={"150px"} />
                </>
                :
                <>
                    <Grid>
                        {lastUpdatedAt &&
                            <Typography marginTop="1.0em" align="left" sx={{ textDecoration: "underline" }}>
                                {localize("sensor-info.last-updated-at")}
                                {lastUpdatedAt}
                            </Typography>
                        }
                    </Grid>
                    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={handleUpdateQuery}
                                disabled={communicationErrorExists || isExecuting || gatewayNotConnected}
                                endIcon={isExecuting && <CircularProgress size={"1rem"} />}
                                sx={{
                                    marginTop: "1.0em"
                                }}>
                                {pageDirection === 0 ?
                                    <Typography variant="button" style={{ display: 'flex', alignItems: 'center' }}>
                                        {isExecuting ? "Fetching..." : (
                                            <>
                                                {localize("sensor-info.update")} <Refresh fontSize="small" />
                                            </>
                                        )}
                                    </Typography>
                                    :
                                    <Typography variant="button" style={{ display: 'flex', alignItems: 'center' }}>
                                        {isExecuting ? "Fetching..." : (
                                            <>
                                                {localize("sensor-info.next")} <NavigateNext fontSize="small" />
                                            </>
                                        )}
                                    </Typography>
                                }
                                {isExecuting && (
                                    <Box width='100%' position="absolute" top="90%">
                                        <LinearProgress
                                            variant="determinate"
                                            value={currentProgress}
                                        />
                                    </Box>
                                )}
                            </Button>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography marginTop="1.0em" align="right">
                                {localize("sensor-info.total-items")} {totalCount}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            }
        </>);
}

export default SensorInfoTableFotterRow;