import { Box, SxProps, Typography } from "@mui/material"
import { EBLTextField } from "components/StyledComponents/TextField"
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

interface InvoiceTextFieldWithHeaderProps {
    text: string,
    placeholder: string | number,
    id: string | number,
    sx: SxProps,
    disabled: boolean,
    value?: string | number | null,
    onChange?: (e: any) => void,
    ending?: React.ReactNode
}



const InvoiceTextFieldWithHeader: React.FC<InvoiceTextFieldWithHeaderProps> = ({
    text, placeholder, id, sx, disabled, value, onChange, ending }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <>
            <Typography sx={{ fontWeight: "bold", mt: "3em" }}>
                {localize(`invoicing.${text}`)}
            </Typography>

            <Box display="flex" alignItems="center">
                <EBLTextField
                    id={`${id}`}
                    sx={sx}
                    variant="outlined"
                    placeholder={placeholder ? placeholder.toString() : ""}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    InputProps={{
                        style: {
                            fontWeight: 'bold',
                        },
                    }} />
                {ending}
            </Box>
        </>
    )
};

export default InvoiceTextFieldWithHeader;