import React from "react";
import EventsTable from "../components/events/EventsTable";
import { useParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { fetchFireAlarmSystem } from "adapters/fireAlarmSystem";
import AlarmSystemBreadcrumbs from "components/AlarmSystemBreadcrumbs";

const Events: React.FC = () => {
    const params = useParams();
    const alarmSystemId = params.id || "alarm";

    const {
        data,
        isLoading
    } = useQuery({
        queryKey: [alarmSystemId],
        queryFn: async () => {
            const response = await fetchFireAlarmSystem(alarmSystemId);
            return {
                data: response.data,
            };
        },
    });

    return (
        <>
            <AlarmSystemBreadcrumbs
                alarmSystem={data?.data} 
                isLoading={isLoading} 
                paths={["navigation.status", "navigation.events"]} />
            <EventsTable alarmSystemId={alarmSystemId} />
        </>
    );
};

export default Events;