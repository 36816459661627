import React from "react";
import { fetchJournal } from "adapters/fireAlarmSystem";
import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import useDateFormat from "hooks/useDateFormat";
import JournalTableFotterRow from "./JournalTableFotterRow";
import { JournalIcon } from "components/svg/JournalIcon";
import { RenderMobileTablesContent } from "components/RenderMobileTablesContent";
import { IJournal } from "interfaces/fireAlarmSystem";
import { Columns, RenderTablesHeaders } from "components/RenderTablesHeaders";

type TSortHeader = "created" | "icon" | "entry_text" | "written_by_email";

const LoadingJournalSkelton: React.FC = () => {
    return (
        <StyledTableRow>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
            <StyledTableCell >
                <Skeleton />
            </StyledTableCell>
        </StyledTableRow >
    );
};


const JournalTable: React.FC<{ alarmSystemId: string | undefined }> = ({ alarmSystemId }) => {
    const { localize } = React.useContext(LocaleContext);
    const [order, setOrder] = React.useState<"asc" | "desc">('desc');
    const [orderBy] = React.useState<TSortHeader>('created');
    const [totalItems, setTotalItems] = React.useState(0);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));

    const journalQuery = useInfiniteQuery({
        queryKey: [`journal-${alarmSystemId}`, order, orderBy],
        queryFn: async ({ pageParam }) => {
            const params = {
                fire_alarm_system: alarmSystemId,
                page: pageParam,
                ordering: order === "asc" ? orderBy : `-${orderBy}`,
            };
            const res = await fetchJournal({ params: params })
            setTotalItems(res.data.count);
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    const handleRequestSort = () => {
        const isAsc = orderBy === "created" && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    React.useEffect(() => {
        if (inView) {
            journalQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const columns: Columns[] = [
        {
            text: "journal.journal-time",
            sort_header: "created",
            enable_sorting: true
        },

        {
            text: "",
            enable_sorting: false
        },
        {
            text: "journal.journal-text",
            enable_sorting: false
        },
        {
            text: "journal.journal-user",
            enable_sorting: false
        }
    ]

    const handleUpdateQuery: () => void = () => {
        journalQuery.refetch();
    };

    const rows = (entity: IJournal) => {
        if (entity.type === "journal") {
            return [
                {
                    title: "journal.journal-time",
                    content: formatDate(entity.created),
                    sortHeader: "created",
                    orderBy: "created",
                    order: order,
                    handleRequestSort: handleRequestSort
                }, {
                    title: "",
                    content: <JournalIcon color="primary" />
                }, {
                    title: "journal.journal-text",
                    content: entity.entry_text
                }, {
                    title: "journal.journal-user",
                    content: entity.written_by_email
                }

            ]
        }
        else {
            return [
                {
                    type: "journal",
                    title: "journal.journal-time",
                    content: formatDate(entity.created),
                    sortHeader: "created",
                    orderBy: "created",
                    order: order,
                    handleRequestSort: handleRequestSort
                }, {
                    type: "command",
                    title: "journal.journal-text",
                    content: entity.entry_text + '\n'
                        + entity.command_status + '\n'
                        + entity.command_reason
                }, {
                    type: "journal",
                    title: "journal.journal-user",
                    content: entity.written_by_email
                }

            ]
        }

    };

    return (
        <>
            <StyledTableContainer>
                <Table sx={{ width: '100%' }} size="small" stickyHeader >
                    {!isSm ?
                        <TableHead>
                            <RenderTablesHeaders
                                columns={columns}
                                handleRequestSort={handleRequestSort}
                                order={order}
                                orderBy={orderBy}
                            />
                        </TableHead>
                        : ""}
                    <TableBody>
                        {journalQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("journal.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        ) :
                            journalQuery.data?.pages.map((datablock, i) => datablock.data.map((entity, j) => (
                                !isSm ?
                                    <StyledTableRow
                                        key={entity.id}
                                        ref={isLastInArray(journalQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                                    >
                                        <StyledTableCell >{formatDate(entity.created)}</StyledTableCell>
                                        {entity.type === "journal" ? (
                                            <StyledTableCell style={{ textAlign: "right" }}>
                                                <JournalIcon color="primary" />
                                            </StyledTableCell>
                                        ) :
                                            <StyledTableCell >
                                                {'\u00A0'}
                                            </StyledTableCell>}
                                        {entity.type === "command" ? (
                                            <StyledTableCell>
                                                {entity.entry_text} {'\n'}
                                                {entity.command_status} {'\n'}
                                                {entity.command_reason}
                                            </StyledTableCell>
                                        ) :
                                            <StyledTableCell>
                                                {entity.entry_text}
                                            </StyledTableCell>
                                        }
                                        <StyledTableCell>{entity.written_by_email}</StyledTableCell>

                                    </StyledTableRow>
                                    :

                                    <StyledTableRow
                                        key={entity.id}
                                        ref={isLastInArray(journalQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                                    >
                                        <StyledTableCell>
                                            {rows(entity).map((row, index) => (
                                                <RenderMobileTablesContent key={index}
                                                    title={row.title} content={row.content}
                                                    sortHeader={row.sortHeader} orderBy={row.orderBy} order={row.order}
                                                    handleRequestSort={row.handleRequestSort} />
                                            ))}
                                        </StyledTableCell>

                                    </StyledTableRow>
                            )))
                        }
                        {!isSm ?
                            journalQuery.isFetchingNextPage && ref != null && (
                                <LoadingJournalSkelton />
                            )
                            :
                            (journalQuery.isFetchingNextPage && ref != null &&
                                <TableRow>
                                    <StyledTableCell>
                                        <Skeleton sx={{ width: '80vw' }} />
                                    </StyledTableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <JournalTableFotterRow alarmSystemId={alarmSystemId} totalCount={totalItems} handleUpdateQuery={handleUpdateQuery} order={order} orderBy={orderBy} />

        </>
    )
}

export default JournalTable;