import AlarmSystemBreadcrumbs from "components/AlarmSystemBreadcrumbs";
import SSDFilesTable from "components/ssd-files/SsdFilesTable";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import React from "react";


const SSDBackupView: React.FC = () => {
    const { alarmSystem, alarmSystemId, isLoading: isLoadingSystem } = useGetAlarmSystem();
    return (
        <>
            <AlarmSystemBreadcrumbs
                alarmSystem={alarmSystem}
                isLoading={isLoadingSystem}
                paths={["navigation.settings", "navigation.ssd-files"]} />
            <SSDFilesTable alarmSystemId={alarmSystemId}/>
        </>
        
    );
};

export default SSDBackupView;