import { LocaleContext } from "contexts/LocaleContext";
import React from "react";

import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from "@mui/material";

import { StatusAccordionContainer } from "components/StyledComponents/Accordion";
import {
    FireAlarmStatusAccordion,
    PreFireAlarmStatusAccordion,
    FaultStatusAccordion,
    DisablementStatusAccordion,
    TechWarnStatusAccordion,
    InterlockingStatusAccordion,
    DoorOpenStatusAccordion,
    TestZonesStatusAccordion,
    ServiceSignalStatusAccordion,
    OutputActivationStatusAccordion,
    CommunicationErrorStatusAccordion
} from "components/status/accordions";
import useDateFormat from "hooks/useDateFormat";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import AlarmSystemBreadcrumbs from "components/AlarmSystemBreadcrumbs";

const Status: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const params = useParams();
    const navigate = useNavigate();
    const { formatDate } = useDateFormat();
    const { alarmSystem, isLoading } = useGetAlarmSystem();
    return (
        <>
            <AlarmSystemBreadcrumbs
                alarmSystem={alarmSystem} 
                isLoading={isLoading} 
                paths={["navigation.status", "navigation.deviations"]}
                extra={
                    <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={() => navigate(`/${alarmSystem?.local_office}/configuration/${params.id}`)}
                        sx={{
                            marginBottom: "2.750em",
                            padding: "12px 24px",
                            display: { xs: "none", sm: "inherit" }
                        }}>
                        <Typography variant="button">
                            {localize("status.detail-button")}
                        </Typography>
                    </Button>
                }
            />
            <StatusAccordionContainer>
                <FireAlarmStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <PreFireAlarmStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <FaultStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <DisablementStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <TechWarnStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <InterlockingStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <DoorOpenStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <TestZonesStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <ServiceSignalStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <OutputActivationStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
                <CommunicationErrorStatusAccordion alarmSystem={alarmSystem} formatDate={formatDate} />
            </StatusAccordionContainer>
        </>
    );
};

export default Status;