import { Grid, TableSortLabel } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";


interface MobileTablesContentProps {
    title: string,
    content: string | undefined | React.ReactNode,
    sortHeader?: string,
    orderBy?: string,
    order?: "desc" | "asc" | undefined,
    handleRequestSort?: (param: string) => void;
}

export const RenderMobileTablesContent: React.FC<MobileTablesContentProps> = ({ title, content, sortHeader, orderBy, order, handleRequestSort }) => {
    const { localize } = React.useContext(LocaleContext);


    return (
        <Grid container key={typeof content === "string" ? content : "1"} mb="0.5rem">
            <Grid item xs={4}>
                {!!handleRequestSort && !!sortHeader ?
                    <TableSortLabel
                        key={typeof content === "string" ? content : "1"}
                        active={orderBy === sortHeader}
                        direction={orderBy === sortHeader ? order : 'asc'}
                        onClick={() => handleRequestSort(sortHeader)}
                    >
                        {localize(title)}
                    </TableSortLabel>
                    :
                    localize(title)
                }
            </Grid>
            <Grid item xs={8}>
                {content}
            </Grid>
        </Grid>
    )
};