import React from "react";

import { useHasPermission } from "hooks/useHasPermission";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import JournalTable from "components/journal/JournalTable";
import AlarmSystemBreadcrumbs from "components/AlarmSystemBreadcrumbs";

const JournalView: React.FC = () => {

    const { alarmSystemId, alarmSystem, isLoading } = useGetAlarmSystem();
    const { hasPermission } = useHasPermission("localoffice");

    return (
        <>
            <AlarmSystemBreadcrumbs
                alarmSystem={alarmSystem} 
                isLoading={isLoading} 
                paths={["navigation.status", "navigation.journal"]} />
            {hasPermission("journal", "view_journal") && (
                <JournalTable alarmSystemId={alarmSystemId} />
            )}
        </>
    );
};

export default JournalView;