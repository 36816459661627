import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styling/theme';
import CssBaseline from '@mui/material/CssBaseline';
import RouteWrapper from 'RouteWrapper';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LocaleProvider from 'contexts/LocaleContext';
import SnackBarProvider from 'contexts/SnackBarContext';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <CssBaseline />
        <LocaleProvider>
          <SnackBarProvider>
              <QueryClientProvider client={queryClient}>
                <RouteWrapper />
                <ReactQueryDevtools initialIsOpen={false} position="bottom" />
              </QueryClientProvider>
          </SnackBarProvider>
        </LocaleProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
