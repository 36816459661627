import { Skeleton, TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

export const StyledTableCell = styled(TableCell)(() => ({
  borderWidth: 0,
  textAlign: 'left',
  maxWidth: '30vw',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word'
}));

export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  height: '80vh',
  [theme.breakpoints.down("sm")]: {
    height: '60vh'
  }

}));

export const LoadingSkeletonRows: React.FC<{columns: number, rows?: number}> = ({
  columns,
  rows = 5
}) => {

  const array = useMemo(() => {
    return [...Array(rows)].map(() => Array(columns).fill(0))
  }, [columns, rows])

  return (
      <>
        {array.map((e, index) => (
          <StyledTableRow key={index}>
            {e.map((f, cellIndex) => (
              <StyledTableCell key={cellIndex} >
                  <Skeleton />
              </StyledTableCell>
            ))}
          </StyledTableRow >
        ))}
      </>
  );
};