import webAPIRequest, { IPaginatedData } from "api";
import { AxiosRequestConfig } from "axios";
import { IInvoiceExport, IInvoiceSettings } from "interfaces/invoicing";


export const fetchInvoiceExport = async (opts: AxiosRequestConfig) =>
    webAPIRequest<IPaginatedData<IInvoiceExport>>("GET", "/invoicing/invoice-export/", opts);

export const createInvoiceExport = async () =>
    webAPIRequest<IInvoiceExport>("POST", "/invoicing/invoice-export/");

export const fetchInvoiceSettings = async () =>
    webAPIRequest<IInvoiceSettings>("GET", "/invoicing/invoice-settings/");

export const patchInvoiceSettings = async (id: string, data: Partial<IInvoiceSettings>) =>
    webAPIRequest<IInvoiceSettings>("PATCH", `/invoicing/invoice-settings/${id}/`, { data: data });
