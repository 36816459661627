import { Box, Button, FormControl, ListItem, ListItemText, Radio, RadioGroup, Skeleton, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchInvoiceSettings, patchInvoiceSettings } from "adapters/invoicing";
import { AxiosError } from "axios";
import { StyledFormControlInvoiceLabel } from "components/StyledComponents/FormControlLabel";
import { LocaleContext } from "contexts/LocaleContext";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useHasPermission } from "hooks/useHasPermission";
import { IInvoiceSettings, InvoiceFrequency } from "interfaces/invoicing";
import React from "react";
import { getFieldErrorMessage } from "utils/getFieldErrorMessage";
import InvoiceTextFieldWithHeader from "./InvoiceTextFieldWithHeader";

const LoadingInvoiceSettingsSkeleton: React.FC = () => {
    return (
        <ListItem
            disableGutters
            divider
        >
            <ListItemText
                sx={{ margin: 0, mt: 4 }}
                primaryTypographyProps={{ variant: "subtitle2" }}
                primary={<Skeleton width="50%" />}
            />
        </ListItem>
    );
};


const InvoiceSettings: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { hasPermission } = useHasPermission();
    const [dayOfMonth, setDayOfMonth] = React.useState<number>(0)
    const [daysForRemove, setDaysForRemove] = React.useState<number>(0)
    const [FrequencyValue, setFrequencyValue] = React.useState("");
    const queryClient = useQueryClient();

    const [invoiceSettings, setInvoiceSettings] = React.useState({
        email: "",
        frequency: 0,
        day_of_month: 0,
        auto_delete_max_days: 0
    });

    const currentStatus = (invoice_frequency: number): string => {
        switch (invoice_frequency) {
            case InvoiceFrequency.ONCE_EVERY_MONTH:
                return "1"
            case InvoiceFrequency.ONCE_EVERY_THREE_MONTHS:
                return "3"
            case InvoiceFrequency.ONCE_EVERY_SIX_MONTHS:
                return "6"
            default:
                return ""
        }

    }

    const invoiceSettingsQuery = useQuery({
        queryKey: ["invoice-settings"],
        queryFn:
            async () => {
                const queryResult = await fetchInvoiceSettings();
                if (queryResult.data) {
                    setInvoiceSettings({ ...invoiceSettings, ...queryResult.data })
                    setFrequencyValue(currentStatus(queryResult.data.frequency));
                    setDayOfMonth(queryResult.data.day_of_month)
                    setDaysForRemove(queryResult.data.auto_delete_max_days)
                }
                return queryResult.data
            }
    });

    const updateInvoiceSettingsMutation = useMutation({
        mutationKey: ["patch-invoice-settings"],
        mutationFn: async (data: Partial<IInvoiceSettings>) => {
            const invoiceId = invoiceSettingsQuery.data?.id;

            return await patchInvoiceSettings(invoiceId ?? "", data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["invoice-settings"] });
            invoiceSettingsQuery.refetch();
            openSnack(localize("invoicing.invoice-success"), "success");

        },
        onError: (error: AxiosError) => {
            const fieldError = getFieldErrorMessage(error)
            if (fieldError) {
                openSnack(localize("invoicing.invoice-error") + `: ${fieldError.message}`, "error");
            }
        }
    });

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        updateInvoiceSettingsMutation.mutate({
            frequency: Number(FrequencyValue),
            day_of_month: dayOfMonth,
            auto_delete_max_days: daysForRemove
        });
    };


    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFrequencyValue(event.target.value);
    };

    return (
        <Box maxWidth="sm" >
            <Typography variant="h2" color="primary" gutterBottom>
                {localize("invoicing.invoice-settings")}
            </Typography>
            {hasPermission("invoiceexport", "add_invoiceexport") && invoiceSettingsQuery.isFetching ? (
                <>
                    <LoadingInvoiceSettingsSkeleton />
                    <LoadingInvoiceSettingsSkeleton />
                    <LoadingInvoiceSettingsSkeleton />
                    <LoadingInvoiceSettingsSkeleton />
                </>
            ) :
                <form
                    onSubmit={handleSubmit}
                >
                    <InvoiceTextFieldWithHeader
                        id={"email"}
                        text={"invoice-email-lable"}
                        placeholder={invoiceSettings.email}
                        sx={{
                            mt: "0.5em", width: "100%"
                        }}
                        disabled={true} />

                    <FormControl sx={{ mt: 3 }}
                        variant="standard">
                        <Typography sx={{ fontWeight: "bold", color: "primary" }} id="invoice-frequency">{localize("invoicing.invoice-frequency")}</Typography>
                        <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={FrequencyValue}
                            onChange={handleRadioChange}
                        >
                            <StyledFormControlInvoiceLabel value="1" control={<Radio />} label={localize("invoicing.invoice-frequency-oneMonth")} />
                            <StyledFormControlInvoiceLabel value="3" control={<Radio />} label={localize("invoicing.invoice-frequency-threeMonth")} />
                            <StyledFormControlInvoiceLabel value="6" control={<Radio />} label={localize("invoicing.invoice-frequency-sixMonth")} />
                        </RadioGroup>

                        <InvoiceTextFieldWithHeader
                            id={"day-of-month"}
                            text={"invoice-day-of-month"}
                            placeholder={invoiceSettings.day_of_month}
                            sx={{
                                mt: "0.5em", width: '4em',
                                '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                }
                            }}
                            disabled={false}
                            value={dayOfMonth}
                            onChange={e => setDayOfMonth(e.target.value)}
                        />


                        <InvoiceTextFieldWithHeader
                            id={"auto-delete-days"}
                            text={"invoice-auto-delete-days"}
                            placeholder={invoiceSettings.auto_delete_max_days}
                            sx={{
                                mt: "0.5em", width: '4em',
                                '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                }
                            }}
                            disabled={false}
                            value={daysForRemove}
                            onChange={e => setDaysForRemove(e.target.value)}
                            ending={<Typography
                                id="invoice-days"
                                sx={{ fontWeight: "bold", color: "primary", marginLeft: 1 }}>
                                {localize("invoicing.invoice-days")}
                            </Typography>}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            sx={{ mt: "20em", mr: "1", maxWidth: 'fit-content' }}
                        >
                            {localize("invoicing.save")}
                        </Button>

                    </FormControl>
                </form>
            }
        </Box>
    );
};

export default InvoiceSettings;