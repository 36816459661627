

export interface IInvoiceExport {
    id: string;
    created: string;
    file: string;
    created_by?: string;
}

export enum InvoiceFrequency {
    "ONCE_EVERY_MONTH" = 1,
    "ONCE_EVERY_THREE_MONTHS" = 3,
    "ONCE_EVERY_SIX_MONTHS" = 6
}

export interface IInvoiceSettings {
    id?: string;
    created?: string;
    email: string;
    frequency: number;
    day_of_month: number;
    auto_delete_max_days: number;
}