import { Typography, Stack, Paper, Grid, IconButton, Tooltip, styled, DialogTitle, Button, DialogContent, Box, LinearProgress, CircularProgress } from "@mui/material";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { DisablementIcon } from "components/svg/DeviationIcons";
import { ICreateCommand, IDeviation, IFireAlarmSystem } from "interfaces/fireAlarmSystem";
import React from "react";
import { fetchDeviations } from "../../adapters/fireAlarmSystem";
import useDateFormat from "hooks/useDateFormat";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { LocaleContext } from "contexts/LocaleContext";
import { differenceInSeconds } from "date-fns";
import { BaseDialog, BaseDialogActions } from "components/StyledComponents/Dialog";
import { EBLTextField } from "components/StyledComponents/TextField";
import useOperations from "hooks/useOperations";
import { SnackBarContext } from "contexts/SnackBarContext";
import { getMacAddress } from "utils/getMacAdress";
import { useSearchParams } from "react-router-dom";
import InfiniteScrollList from "components/InfiniteScrollList";
import { formatTimeTwoDigits } from "utils/dateAndTimeFormating";
import useHasPermission from "hooks/useHasPermission";

const WaveEffect = styled("div")
    (() => ({
        "@keyframes wave-animation": {
            "0%": {
                backgroundPosition: "200% 0",
            },
            "100%": {
                backgroundPosition: "-200% 0"
            }
        },
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "linear-gradient(90deg, transparent 25%, rgba(255, 255, 255, 0.5) 50%, transparent 75%)",
        backgroundSize: "200% 100%",
        animation: "wave-animation 4s linear infinite"
    })
    );

const ActiveDisablementPaper = styled(Paper, { shouldForwardProp: (prop) => prop.toString() !== "disabled" })<{ disabled: boolean }>(
    (({ theme, disabled }) => ({
        padding: "4px",
        position: "relative",
        backgroundColor: theme.palette.warning.main,
        opacity: disabled ? 0.5 : 1,
    }))
);


const ActiveDisablement: React.FC<{ deviation: IDeviation, alarmSystem: IFireAlarmSystem, gatewayNotConnected: boolean }> = ({ deviation, alarmSystem, gatewayNotConnected }) => {
    const { localize } = React.useContext(LocaleContext);
    const { openSnack } = React.useContext(SnackBarContext);
    const { formatDate } = useDateFormat();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deviationDialogOpen, setDeviationDialogState] = React.useState(false);
    const [reasonField, setReasonField] = React.useState("");
    const [isAboutToDisappear, setisAboutToDisappear] = React.useState(false);
    const { hasPermission } = useHasPermission("localoffice");

    const onSuccess = () => {
        setReasonField("");
        openSnack(localize(`operations.command-executed`), "success");
        onClose();
        setisAboutToDisappear(true);
    };

    const onError = () => {
        openSnack(localize(`operations.general-warning`), "warning");
        setSearchParams({ ...searchParams, modal: 'operations-error' })
    };

    const { createOperation, isExecuting, progress } = useOperations(onSuccess, onError);

    const zoneAddress = (): string => {
        const zone = deviation.payload.data.parameters.zone.toString().padStart(3, '0');

        const addressNumber = deviation.payload.data.parameters.address ?? '';
        let address = '';

        if (addressNumber !== 0) {
            address = `Address ${addressNumber.toString().padStart(2, '0')} `;
        }
        return `Zone ${zone} ${address}`;
    }

    const commandInfo = (): string => {
        const smokeOnly = deviation.payload.data.parameters.smokeonly ? `(smoke only)` : "";
        const datetime = formatDate(deviation.datetime);
        const autoRe = deviation.payload.data.parameters.autore;

        if (autoRe) {
            const autoHour = deviation.payload.data.parameters.autohour || 0;
            const autoMinute = deviation.payload.data.parameters.autominute || 0;
            return `${zoneAddress()}${smokeOnly} ${datetime} ${'\u00A0\u00A0'} ${localize("operations.reenable")} ${"\u23F0"} ${formatTimeTwoDigits(autoHour)}:${formatTimeTwoDigits(autoMinute)}`;
        }

        return `${zoneAddress()}${smokeOnly} ${datetime}`;
    };

    const onOpen = () => {
        setDeviationDialogState(true);
    };

    const onClose = () => {
        setDeviationDialogState(false);
    };

    const onReenable = (event: React.SyntheticEvent) => {
        event.preventDefault();
        createOperation(getArgs());
    };

    const getArgs = (): ICreateCommand => {
        return {
            fire_alarm_system: alarmSystem.id,
            version: 0,
            type: "cmd",
            source: getMacAddress(alarmSystem),
            user: "",
            seq_ack: 0,
            subject: "cmd/disablement/alarmpoint",
            reason: reasonField ? reasonField : "-",
            payload: {
                cmdtype: "execute",
                jobid: "",
                data: {
                    disable: false,
                    parameters: {
                        zone: Number(deviation.payload.data.parameters.zone),
                        address: Number(deviation.payload.data.parameters.address ?? 0),
                        autore: false,
                        autohour: undefined,
                        autominute: undefined,
                        smokeonly: deviation.payload.data.parameters.smokeonly
                    }
                }
            }
        }
    };

    const communicationError = alarmSystem?.deviations.communication_error
    const communicationErrorExists = (communicationError ?? []).length >= 1 ? true : false

    const isNew = differenceInSeconds(Date.now(), Date.parse(deviation.datetime)) < 30
    return (
        <ActiveDisablementPaper elevation={0} disabled={isAboutToDisappear}>
            {(isNew || isAboutToDisappear) && (<WaveEffect />)}
            <Grid container>
                <Grid item xs={10}>
                    <Stack direction="row" gap="4px" alignItems="flex-start">
                        <DisablementIcon />
                        <Typography variant="body1">
                            {commandInfo()}
                        </Typography>
                    </Stack>
                </Grid>
                {hasPermission("command", "change_command") &&
                    <Grid item xs={2}>
                        <Stack direction="row" gap="4px" justifyContent="flex-end" alignItems="center">
                            <Tooltip title={localize("operations.reenable")}>
                                <IconButton color="primary" sx={{ padding: 0 }} onClick={onOpen} disabled={isAboutToDisappear}>
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                }
            </Grid>
            <BaseDialog open={deviationDialogOpen} onClose={onClose}>
                <DialogTitle color="primary">
                    {localize("operations.reenable")} {zoneAddress()}
                </DialogTitle>
                <DialogContent>
                    <EBLTextField
                        variant="outlined"
                        label={localize("operations.reenable-reason")}
                        id="reason-input"
                        value={reasonField}
                        fullWidth
                        disabled={isExecuting}
                        placeholder={localize("operations.reason-example")}
                        onChange={(e) => setReasonField(e.target.value)}
                        multiline
                        minRows={2}
                        helperText={`${reasonField.length} / 100`}
                        inputProps={{ maxLength: 100 }}
                    />
                </DialogContent>
                <BaseDialogActions>
                    <Button variant="outlined" onClick={onClose} disabled={isExecuting}>
                        {localize("generics.close")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onReenable}
                        disabled={communicationErrorExists || isExecuting || gatewayNotConnected}
                        endIcon={isExecuting && <CircularProgress size={"1rem"} />}
                    >
                        <Typography variant="button">
                            {isExecuting ? "Executing" : (
                                localize("operations.reenable")
                            )}
                        </Typography>
                        {isExecuting && (
                            <Box width='100%' position="absolute" top="90%">
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                />
                            </Box>
                        )}
                    </Button>
                </BaseDialogActions>
            </BaseDialog>
        </ActiveDisablementPaper>
    )
};

const ListActiveDisablements: React.FC<{ alarmSystem: IFireAlarmSystem, gatewayNotConnected: boolean }> = ({ alarmSystem, gatewayNotConnected }) => {
    const disablementParams = {
        'fire_alarm_system': alarmSystem.id,
        "devtype": "dev/disablement/alarmpoint",
        "ordering": "-datetime",
        "active": true
    };
    const disablementsQuery = useInfiniteQuery({
        queryKey: ["deviations", alarmSystem.id, disablementParams],
        queryFn: async ({ pageParam }) => {
            const params = { 'page': pageParam, ...disablementParams };
            const response = await fetchDeviations({ params });
            return {
                data: response.data.results,
                nextPage: response.data.next ? pageParam + 1 : undefined,
            };
        },
        refetchInterval: 10000,
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
    });

    return (
        <Box height={"70vh"}>
            <InfiniteScrollList
                fetchNextPage={disablementsQuery.fetchNextPage}
                isFetchingNextPage={disablementsQuery.isFetchingNextPage}
                hasNextPage={!!disablementsQuery.hasNextPage}
                isLoading={disablementsQuery.isLoading}
            >
                {disablementsQuery.data?.pages.map((group, i) => group.data.map((deviation, j) => (
                    <ActiveDisablement
                        gatewayNotConnected={gatewayNotConnected}
                        key={deviation.id}
                        alarmSystem={alarmSystem}
                        deviation={deviation}
                    />
                )))}
            </InfiniteScrollList>
        </Box>
    )
};

export default ListActiveDisablements;