import { StackedBarChart, Home, ShowChart, PlaylistAddCheck, NotificationsNone, People, Person, Logout, Settings, AddCircle, Groups, Receipt, GraphicEq, QuestionMarkOutlined, Edit, Backup } from "@mui/icons-material";
import { ModelType, PermissionType } from "adapters/auth";
import { EventsIcon } from "./svg/NavigationIcons";
import { JournalIcon } from "./svg/JournalIcon";
import { DisablementIcon } from "./svg/DeviationIcons";

export interface INavigationItem {
    id: string;
    text: string;
    icon?: React.ReactNode;
    route?: string;
    query?: Record<string, string>;
    pushEnd?: boolean;
    bottom?: boolean;
    useAlarmSystem?: boolean;
    nullsAlarmSystem?: boolean;
    permissionParams: [ModelType, PermissionType<ModelType>];
    featureFlags?: string[];
    externalLink?: string;
    subMenus?: INavigationItem[];
    mainMenu?: string;
};

export const BaseNavigationItems: INavigationItem[] = [
    {
        id: "overview",
        text: "navigation.overview",
        icon: <Home color="primary" />,
        route: "/",
        bottom: true,
        nullsAlarmSystem: true,
        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
    },
    {
        id: "organization-management",
        text: "navigation.organization-management",
        icon: <Groups color="primary" />,
        route: "/",
        bottom: true,
        permissionParams: ["organization", "add_organization"]
    },
    {
        id: "alarm-system-menu",
        text: "",
        bottom: true,
        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
        subMenus: [
            {
                id: "status-menu",
                text: "navigation.status",
                icon: <ShowChart color="primary" />,
                route: "/status/",
                useAlarmSystem: true,
                permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                subMenus: [
                    {
                        id: "status",
                        text: "navigation.deviations",
                        icon: <GraphicEq color="primary" />,
                        route: "/status/",
                        useAlarmSystem: true,
                        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                        mainMenu: "status-menu"
                    },
                    {
                        id: "events",
                        text: "navigation.events",
                        icon: <EventsIcon color="primary" />,
                        route: "/events/",
                        useAlarmSystem: true,
                        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                        mainMenu: "status-menu"
                    },
                    {
                        id: "journal",
                        text: "navigation.journal",
                        icon: <JournalIcon color="primary" />,
                        route: "/journal/",
                        useAlarmSystem: true,
                        permissionParams: ["journal", "view_journal"],
                        mainMenu: "status-menu"
                    }
                ]
            },
            {
                id: "operations-menu",
                text: "navigation.operations",
                icon: <PlaylistAddCheck color="primary" />,
                route: "/operations/",
                useAlarmSystem: true,
                subMenus: [
                    {
                        id: "disablement",
                        text: "navigation.disablement",
                        icon: <DisablementIcon color="primary" />,
                        route: "/operations/",
                        useAlarmSystem: true,
                        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                        mainMenu: "operations-menu"
                    },
                    {
                        id: "diagnostics",
                        text: "navigation.diagnostics",
                        icon: <StackedBarChart color="primary" className="useStyles"/>,
                        route: "/diagnostics/",
                        useAlarmSystem: true,
                        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                        mainMenu: "operations-menu"
                    }
                ],
                permissionParams: ["firealarmsystem", "view_firealarmsystem"]
            },
            {
                id: "configuration-menu",
                text: "navigation.settings",
                icon: <Settings color="primary" />,
                route: "/configuration/",
                useAlarmSystem: true,
                subMenus: [
                    {
                        id: "configuration",
                        text: "navigation.configuration",
                        icon: <Edit color="primary" />,
                        route: "/configuration/",
                        useAlarmSystem: true,
                        permissionParams: ["firealarmsystem", "view_firealarmsystem"],
                        mainMenu: "configuration-menu"
                    },
                    {
                        id: "notifications",
                        text: "navigation.notifications",
                        icon: <NotificationsNone color="primary" />,
                        route: "/notifications/",
                        useAlarmSystem: true,
                        permissionParams: ["notificationpreference", "add_notificationpreference"],
                        mainMenu: "configuration-menu"
                    },
                    {
                        id: "ssd-files",
                        text: "navigation.ssd-files",
                        icon: <Backup color="primary" />,
                        route: "/ssd-files/",
                        useAlarmSystem: true,
                        permissionParams: ["ssdfileversion", "view_ssdfileversion"],
                        mainMenu: "configuration-menu",
                        featureFlags: ["navigation_ssd_files"]
                    }
                ],
                permissionParams: ["firealarmsystem", "view_firealarmsystem"]
            },
        ]

    },

    {
        id: "add-alarm",
        text: "navigation.add-alarm",
        icon: <AddCircle color="primary" />,
        query: { "modal": "create-alarm" },
        permissionParams: ["firealarmsystem", "add_firealarmsystem"]
    },
    {
        id: "user-management",
        text: "navigation.user-management",
        icon: <People color="primary" />,
        route: "/users",
        permissionParams: ["user", "add_user"]
    },
    {
        id: "invoice-export",
        text: "navigation.invoice",
        icon: <Receipt color="primary" />,
        route: "/invoice-export",
        bottom: true,
        permissionParams: ["invoiceexport", "view_invoiceexport"]
    },
    {
        id: "account",
        text: "navigation.account",
        icon: <Person color="primary" />,
        route: "/account",
        pushEnd: true,
        permissionParams: ["user", "view_profile"]
    },
    {
        id: "help",
        text: "navigation.help",
        icon: <QuestionMarkOutlined color="primary" />,
        externalLink: "https://www.panasonic-fire-security.com/EBLCloud",
        bottom: true,
        permissionParams: ["firealarmsystem", "view_firealarmsystem"]
    },
    {
        id: "logout",
        text: "navigation.logout",
        icon: <Logout color="primary" />,
        route: "/logout",
        permissionParams: ["user", "view_profile"]
    }
];