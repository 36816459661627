import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledFormControlLabel = styled(FormControlLabel)
    (() => ({
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "0"
    })
    );

export const StyledFormControlInvoiceLabel = styled(FormControlLabel)
    (() => ({
        '& .MuiFormControlLabel-label': {
            fontWeight: 'bold',
        }
    })
    );