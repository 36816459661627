import useClientConfig from "./useClientConfig";
import { useCallback } from "react";

interface IUseIsFeatureActive {
    isFeatureActive: (featureId: string) => boolean;
    isFeaturesActive: (featureIds: string[]) => boolean;
}

const useIsFeatureActive = (): IUseIsFeatureActive =>  {
    const clientConfig = useClientConfig();

    const isFeatureActive = useCallback((featureId: string) => {
        if (!clientConfig.data) {
            return false;   
        }
        return clientConfig.data.data.feature_flags.includes(featureId);
    }, [clientConfig.data]);

    const isFeaturesActive = useCallback((featureIds: string[]) => {
        if (!clientConfig.data) {
            return false;
        }
        return featureIds.every(f => clientConfig.data.data.feature_flags.includes(f));
    }, [clientConfig.data]);


    return {
        isFeatureActive,
        isFeaturesActive
    }
};

export default useIsFeatureActive;