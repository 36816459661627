import React, { useCallback, useEffect, useMemo } from "react";
import { createSensorInfoCommand, fetchSensorInfoCommand, fetchSensorInfo } from "adapters/fireAlarmSystem";
import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import useDateFormat from "hooks/useDateFormat";
import { CommandStatus, ICreateSensorInfoCommand, ISensorItems } from "interfaces/fireAlarmSystem";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useSearchParams } from "react-router-dom";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { getMacAddress } from "utils/getMacAdress";
import SensorInfoTableFotterRow from "./SensorInfoTableFotterRow";
import useGetNewProgressOfStatus from "hooks/useGetNewProgressOfStatus";
import { RenderMobileTablesContent } from "components/RenderMobileTablesContent";
import { Columns, RenderTablesHeaders } from "components/RenderTablesHeaders";


const LoadingSensorInfoSkelton: React.FC = () => {
    const loadingCells = Array.from({ length: 11 }, (_, index) => (
        <StyledTableCell key={index}>
            <Skeleton />
        </StyledTableCell>
    ));

    return (
        <StyledTableRow>
            {loadingCells}
        </StyledTableRow >
    );
};


const SensorInfo: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();
    const [pageDirection, setPageDirection] = React.useState(0);
    const { alarmSystem, isLoading } = useGetAlarmSystem();
    const [lastUpdatedAt, setLastUpdatedAt] = React.useState("")
    const [currentExecutingCommand, setCurrentExecutingCommand] = React.useState<string>("");
    const [currentProgress, setcurrentProgress] = React.useState(0);
    const [isExecuting, setIsExecuting] = React.useState(false);
    const queryClient = useQueryClient();
    const { openSnack } = React.useContext(SnackBarContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const alarmSystemId = sessionStorage.getItem("alarm_system_id");

    const sensorInfoQuery = useInfiniteQuery({
        queryKey: [`sensor-info-${alarmSystemId}`],
        queryFn: async ({ pageParam }) => {
            const params = {
                fire_alarm_system: alarmSystemId,
                page: pageParam,
            };
            const res = await fetchSensorInfo({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        enabled: !!alarmSystemId
    });

    React.useEffect(() => {
        if (inView) {
            sensorInfoQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);


    const columns: Columns[] = [
        {
            text: "sensor-info.type"
        },
        {
            text: "sensor-info.tech-no"
        },
        {
            text: "sensor-info.zone-address"
        },
        {
            text: "sensor-info.momentary"
        },
        {
            text: "sensor-info.weekly"
        },
        {
            text: "sensor-info.perf-factor"
        },
        {
            text: "sensor-info.min"
        },
        {
            text: "sensor-info.max"
        },
        {
            text: "sensor-info.algorithm"
        },
        {
            text: "sensor-info.install-date"
        },
        {
            text: "sensor-info.month-left"
        }
    ]

    const handleUpdateQuery: () => void = () => {
        createOperation(getArgs());
        sensorInfoQuery.refetch();
    };

    const allSensorItems = useMemo(() => {
        const all: ISensorItems[] = [];
        sensorInfoQuery.data?.pages.forEach(page => {
            page.data.forEach(sensor => {
                all.push(...sensor.payload.sensor_items ?? []);
            })
        });
        return all;
    }, [sensorInfoQuery.data?.pages]);

    React.useEffect(() => {
        const last = sensorInfoQuery.data?.pages.at(-1)?.data.at(-1);
        setLastUpdatedAt(last?.datetime ? formatDate(last?.datetime) : "")
        if (last?.payload.sensor_items?.length === 0) {
            setPageDirection(0)
        }

    }, [formatDate, sensorInfoQuery.data?.pages]);

    const lastSensorItem = useMemo(() => {
        const numberOfItems = allSensorItems.length;
        if (numberOfItems === 0) {
            return undefined;
        }
        return allSensorItems[numberOfItems - 1];
    }, [allSensorItems]);

    useEffect(() => {
        if (!lastSensorItem) {
            setPageDirection(0);
        }
    }, [lastSensorItem]);

    const getNewProgressOfStatus = useGetNewProgressOfStatus;

    useQuery({
        queryKey: ["command", currentExecutingCommand],
        queryFn: async () => {
            const queryResult = await fetchSensorInfoCommand(currentExecutingCommand)
            const commandStatus = queryResult.data.status;
            setcurrentProgress((current) => getNewProgressOfStatus(current, commandStatus));
            if (commandStatus === CommandStatus.COMPLETED) {
                resetState();
                onSuccess();
                queryClient.invalidateQueries({ queryKey: [`sensor-info-${alarmSystemId}`] }, { cancelRefetch: true });
            } else if (commandStatus === CommandStatus.FAILED) {
                resetState();
                onError()
            }
            return queryResult ?? null
        },
        enabled: currentExecutingCommand !== "",
        refetchInterval: 1000
    });

    const resetState = () => {
        setIsExecuting(false);
        setcurrentProgress(0);
        setCurrentExecutingCommand("");
    };

    const onSuccess = () => {
        setPageDirection(1)
        openSnack(localize(`sensor-info.fetching-completed-successfully`), "success");
    };

    const onError = () => {
        openSnack(localize(`sensor-info.fetching-failed`), "warning");
        setSearchParams({ ...searchParams, modal: 'operations-error' })
    };

    const commandMutation = useMutation({
        mutationFn: createSensorInfoCommand,
        onMutate: () => {
            setIsExecuting(true);
        },
        onSuccess: (res) => {
            setCurrentExecutingCommand(res.data.id);
            setPageDirection(1);
        },
        onError: (e) => {
            resetState();
            onError();
        }
    })

    const createOperation = (data: ICreateSensorInfoCommand) => {
        commandMutation.mutate(data);
    };

    const refreshInterval = 10000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system", alarmSystemId] })
        }, refreshInterval);

        return () => clearInterval(intervalId);
    }, [alarmSystemId, queryClient]);

    const communicationError = alarmSystem?.deviations.communication_error
    const communicationErrorExists = (communicationError ?? []).length >= 1 ? true : false

    const activeGateway = alarmSystem?.gateways.find(gw => gw.disconnected === null);
    const gatewayNotConnected = activeGateway?.connected === false || !activeGateway

    const getArgs = useCallback((): ICreateSensorInfoCommand => {
        return {
            fire_alarm_system: alarmSystemId,
            version: 0,
            type: "cmd",
            source: getMacAddress(alarmSystem),
            user: "",
            seq_ack: 0,
            subject: "cmd/sensorinfo/request",
            payload: {
                cmdtype: "execute",
                jobid: "",
                data: {
                    parameters: {
                        techno: pageDirection === 0 ? "000001" : lastSensorItem?.techno ?? "000001",
                        dir: !!lastSensorItem ? pageDirection : 0, //pageDirection = 0 or 1
                    }
                }
            }
        }
    }, [alarmSystem, alarmSystemId, lastSensorItem, pageDirection]);

    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));

    const rows = (sensor: ISensorItems) => {
        return [
            {
                title: "sensor-info.type",
                content: sensor.type
            },
            {
                title: "sensor-info.tech-no",
                content: sensor.techno
            },
            {
                title: "sensor-info.zone-address",
                content: sensor.zoneaddress
            },
            {
                title: "sensor-info.momentary",
                content: sensor.mnt
            },
            {
                title: "sensor-info.weekly",
                content: sensor.week
            },
            {
                title: "sensor-info.perf-factor",
                content: sensor.perf
            },
            {
                title: "sensor-info.min",
                content: sensor.min
            },
            {
                title: "sensor-info.max",
                content: sensor.max
            },
            {
                title: "sensor-info.algorithm",
                content: sensor.algo
            },
            {
                title: "sensor-info.install-date",
                content: sensor.instdate
            },
            {
                title: "sensor-info.month-left",
                content: sensor.mnleft
            }
        ]

    }

    return (
        <>
            <StyledTableContainer sx={{ height: '73vh' }} >
                <Table sx={{ width: '100%', overflow: 'hidden' }} size="small" stickyHeader >
                    {!isSm ?
                        <TableHead>
                            <RenderTablesHeaders
                                columns={columns}
                            />
                        </TableHead>
                        : ""}
                    <TableBody>
                        {sensorInfoQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("sensor-info.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        )
                            :
                            allSensorItems.map((sensor_item, z) => (
                                !isSm ?
                                    <StyledTableRow
                                        key={z}
                                        ref={isLastInArray(allSensorItems, z) ? ref : null}
                                    >
                                        <StyledTableCell >{sensor_item.type}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.techno}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.zoneaddress}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.mnt}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.week}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.perf}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.min}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.max}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.algo}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.instdate}</StyledTableCell>
                                        <StyledTableCell >{sensor_item.mnleft}</StyledTableCell>
                                    </StyledTableRow>
                                    :
                                    <StyledTableRow
                                        key={z}
                                        ref={isLastInArray(allSensorItems, z) ? ref : null}
                                    >
                                        <StyledTableCell>
                                            {rows(sensor_item).map((row, index) => (
                                                <RenderMobileTablesContent key={index}
                                                    title={row.title} content={row.content}
                                                />
                                            ))}
                                        </StyledTableCell>
                                    </StyledTableRow>
                            ))
                        }
                        {!isSm ?
                            sensorInfoQuery.isFetchingNextPage && ref != null && (
                                <LoadingSensorInfoSkelton />
                            )
                            :
                            (sensorInfoQuery.isFetchingNextPage && ref != null &&
                                <TableRow>
                                    <StyledTableCell>
                                        <Skeleton sx={{ width: '80vw' }} />
                                    </StyledTableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <SensorInfoTableFotterRow totalCount={allSensorItems.length}
                handleUpdateQuery={handleUpdateQuery} isExecuting={isExecuting} currentProgress={currentProgress}
                lastUpdatedAt={lastUpdatedAt} pageDirection={pageDirection} gatewayNotConnected={gatewayNotConnected}
                communicationErrorExists={communicationErrorExists} isLoading={isLoading} />
        </>
    )
}

export default SensorInfo;