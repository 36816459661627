import { LocaleContext } from "contexts/LocaleContext";
import React, { useMemo } from "react";

import { Box, Skeleton, Typography } from "@mui/material";
import { IFireAlarmSystem } from "interfaces/fireAlarmSystem";

interface IAlarmSystemBreadcrumbs {
    alarmSystem?: IFireAlarmSystem;
    isLoading: boolean;
    paths: string[];
    extra?: React.ReactNode;
}

const AlarmSystemBreadcrumbs: React.FC<IAlarmSystemBreadcrumbs> = ({alarmSystem, isLoading, paths, extra}) => {
    const { localize } = React.useContext(LocaleContext);

    const breadcrumbs = useMemo(() => {
        const base = `${alarmSystem?.name} `;
        return base + paths.reduce((prev, curr) => (prev + ` / ${localize(curr)} `), "")
    }, [alarmSystem?.name, localize, paths]); 
    
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4" sx={{
                marginBottom: "1.375em",
                color: (theme) => theme.palette.primary.main,
                display: { xs: "none", sm: "inherit" }
            }}>
                {isLoading
                    ? <Skeleton width="10rem" />
                    : breadcrumbs}
            </Typography>
            {extra}
        </Box>
    )

}
export default AlarmSystemBreadcrumbs;