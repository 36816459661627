import { TableRow, TableSortLabel } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import React from "react";
import { StyledTableCell } from "./StyledComponents/Table";

export interface Columns { text: string, sort_header?: string, enable_sorting?: Boolean }

interface TablesHeaderProps {
    columns: Columns[];
    handleRequestSort?: (param: string) => void;
    order?: "desc" | "asc" | undefined;
    orderBy?: string;

}

export const RenderTablesHeaders: React.FC<TablesHeaderProps> = ({ columns, handleRequestSort, order, orderBy }) => {
    const { localize } = React.useContext(LocaleContext);

    return (
        <TableRow>
            {columns.map((entry) => (
                !!handleRequestSort && (!!entry.sort_header || !!entry.enable_sorting) ?
                    <StyledTableCell
                        sx={{ wordBreak: "normal" }}
                        key={entry.text}
                    >
                        <TableSortLabel
                            active={orderBy === entry.sort_header}
                            direction={orderBy === entry.sort_header ? order : 'asc'}
                            onClick={() => handleRequestSort(entry.sort_header ?? "")}
                        >
                            {localize(`${entry.text}`)}
                        </TableSortLabel>
                    </StyledTableCell>
                    :
                    <StyledTableCell
                        sx={{ wordBreak: "normal" }}
                        key={entry.text}
                    >
                        {localize(`${entry.text}`)}
                    </StyledTableCell>
            ))}
        </TableRow>
    )
};