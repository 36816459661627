import { Divider, Grid } from "@mui/material";
import InvoiceExportView from "components/Invoice/InvoiceExport";
import InvoiceSettings from "components/Invoice/InvoiceSettings";

import React from "react";
import theme from "styling/theme";



const InvoiceView: React.FC = () => {
    return (
        <Grid container>
            <Grid item
                xs={12}
                lg={6}
                sx={{
                    paddingRight: { lg: "32px" },
                    borderRight: { lg: `1px solid ${theme.palette.grey[600]}` }
                }}>
                <InvoiceSettings />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                sx={{
                    paddingLeft: { lg: "32px" }
                }}
            >
                <Divider sx={{ display: { md: "none" }, mt: "2em", mb: "1em" }}></Divider>
                <InvoiceExportView />
            </Grid>

        </Grid>
    );
};

export default InvoiceView;